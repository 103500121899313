import React, { Suspense, Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { enumUserRoles } from './constants.js';
import PrivateRoute from './helper/PrivateRoutes';
// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const About = React.lazy(() => import("./components/pages/About"));
const Blog = React.lazy(() => import("./components/pages/Blog"));
const Blogdetail = React.lazy(() => import("./components/pages/Blogdetail"));
const Service = React.lazy(() => import("./components/pages/Service"));
const ConsumerServicedetail = React.lazy(() => import("./components/pages/ConsumerServicedetail"));
const Investmentoptions = React.lazy(() => import("./components/pages/Investmentoptions"));
const Investmentoptionsdetail = React.lazy(() => import("./components/pages/Investmentoptionsdetail"));
const Investmentoptionsrequest = React.lazy(() => import("./components/pages/Investmentoptionsrequest"));
const ForgetPassword = React.lazy(() => import("./components/pages/ForgetPassword"));
const ResetPassword = React.lazy(() => import("./components/pages/ResetPassword"));

const Accounttyperequest = React.lazy(() => import("./components/pages/Accounttyperequest"));

const CreditTyperequest = React.lazy(() => import("./components/pages/CreditTyperequest"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Accounttypes = React.lazy(() => import("./components/pages/Accounttypes"));
const Accounttypedetail = React.lazy(() => import("./components/pages/Accounttypedetail"));
const Internationalwiretransfer = React.lazy(() => import("./components/pages/Internationalwiretransfer"));
const Request = React.lazy(() => import("./components/pages/Request"));
const PolicyPrivacy = React.lazy(() => import("./components/pages/PolicyPrivacy"));
const ControlYourData = React.lazy(() => import("./components/pages/ControlYourData"));
const Cookies = React.lazy(() => import("./components/pages/Cookies"));
const Login = React.lazy(() => import("./components/pages/Login"));


const InvestmentoptionsrequestAdmin = React.lazy(() => import("./components/pages/InvestmentoptionsrequestAdmin"));
const InvestmentoptionsrequestAdminNew = React.lazy(() => import("./components/pages/InvestmentoptionsrequestAdminNew"));
const RegisterAccountUserDashboard = React.lazy(() => import("./components/pages/RegisterAccountUserDashboard"));
const RegisterTransferUserDashboard = React.lazy(() => import("./components/pages/RegisterTransferUserDashboard"));
const AccounttyperequestAdmin = React.lazy(() => import("./components/pages/Accounttyperequestadmin"));
const Userdashboard = React.lazy(() => import("./components/pages/Userdashboard"));
const Admindashboard = React.lazy(() => import("./components/pages/Admindashboard"));
const Admindashboarddetail = React.lazy(() => import("./components/pages/Admindashboarddetail"));
const AdmindashboardUserDetail = React.lazy(() => import("./components/pages/AdmindashboardUserDetail"));

const AllMovements = React.lazy(() => import("./components/pages/userAllMovements.js"));


function App() {

  const { user } = useSelector((state) => state.user)


  return (
    <div className="main-body">
      <Router >
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/blog" component={Blog} />

            <Route exact path="/blog-single/:id" component={Blogdetail} />
            <Route exact path="/consumer-services" component={Service} />
            <Route exact path="/consumer-services-detail/:id" component={ConsumerServicedetail} />

            <Route exact path="/account-types" component={Accounttypes} />
            <Route exact path="/account-types-detail/:id" component={Accounttypedetail} />

            <Route exact path="/investment-options" component={Investmentoptions} />
            <Route exact path="/investment-options-detail/:id" component={Investmentoptionsdetail} />
            <Route exact path="/investment-options-request/:id" component={Investmentoptionsrequest} />

            <Route exact path="/international-wire-transfer" component={Internationalwiretransfer} />
            <Route exact path="/contact/" component={Contact} />
            <Route exact path="/contact/:id" component={Contact} />

            <Route exact path="/account-type-request/:id" component={Accounttyperequest} />
            <Route exact path="/credit-request" component={CreditTyperequest} />
            <Route exact path="/product-request/:id" component={Request} />
            <Route exact path="/privacy-policy" component={PolicyPrivacy} />
            <Route exact path="/control-your-data" component={ControlYourData} />
            <Route exact path="/cookies" component={Cookies} />
            <Route exact path="/login" component={Login} />
            

            <Route exact path="/forget-password" component={ForgetPassword} />
            <Route exact path="/reset-password/:id" component={ResetPassword} />


            <PrivateRoute exact path="/finance-dashboard" role={[enumUserRoles.admin, enumUserRoles.user]}
              component={
                user?.role === enumUserRoles.admin ? Admindashboard : Userdashboard
              } />

            <PrivateRoute exact path="/register-account" role={[enumUserRoles.user]} component={RegisterAccountUserDashboard} />
            <PrivateRoute exact path="/transfers" role={[enumUserRoles.user]} component={RegisterTransferUserDashboard} />
            <PrivateRoute exact path="/movements" role={[enumUserRoles.user]} component={AllMovements} />

            <PrivateRoute exact role={[enumUserRoles.admin]} path="/finance-dashboard-detail/:id" component={Admindashboarddetail} />
            <PrivateRoute exact role={[enumUserRoles.admin]} path="/investment-options-request" component={InvestmentoptionsrequestAdmin} />
            <PrivateRoute exact role={[enumUserRoles.admin]} path="/investment-options-request-user/:id" component={InvestmentoptionsrequestAdmin} />
            <PrivateRoute exact role={[enumUserRoles.admin]} path="/investment-options-request-new" component={InvestmentoptionsrequestAdminNew} />
            <PrivateRoute exact role={[enumUserRoles.admin]} path="/account-type-request" component={AccounttyperequestAdmin} />
            <PrivateRoute exact role={[enumUserRoles.admin]} path="/finance-dashboard-account-detail/:id" component={AdmindashboardUserDetail} />


            <Route exact path="/error" component={Error} />
            <Route exact  component={Error} />
            


          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}


export default App;
