import { takeLatest, select, put } from 'redux-saga/effects'

import axios from 'axios';
import { API_URL } from '../constants';
import { setUser, setUsers } from './user'
import { param } from 'jquery';

function* getUser() {
    try {
        const userData = yield select(state => state.user);
        const { jwtToken } = userData;

        const user = yield axios.get(`${API_URL}/user`, {
            headers: { Authorization: `Bearer ${jwtToken}` }
        })

        if (!user || user.status === 200) {
            yield put(setUser(user.data));
        }

    } catch (e) {

    }
}

export function* getUserSaga() {
    yield takeLatest("GETUSERTRANSACT", getUser);
}

export function getUserAction() {
    return { type: "GETUSERTRANSACT" }
}

function* getUsers() {
    try {
        const userData = yield select(state => state.user);
        const { jwtToken } = userData;

        if (userData.user && userData.user.role === "admin") {

            const user = yield axios.get(`${API_URL}/user/getOperatios`, {
                headers: { Authorization: `Bearer ${jwtToken}` }
            })

            if (!user || user.status === 200) {
                yield put(setUsers(user.data));

            }
        }



    } catch (e) {

    }
}

export function* getUsersSaga() {
    yield takeLatest("GETUSERSTRANSACT", getUsers);
}

export function getUsersAction() {
    return { type: "GETUSERSTRANSACT" }
}


function* getExcerptsPDF({ payload }) {
    try {
        const userData = yield select(state => state.user);
        const { jwtToken } = userData;
        const { init, end, product } = payload;

        axios.get(`${API_URL}/user/excerpts`, {
            params: {
                since: init,
                until: end,
                product: product
            },
            headers: { Authorization: `Bearer ${jwtToken}` },
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `LONDONBANK-${String( product).toLocaleUpperCase()}.pdf`); // Nombre del archivo
            document.body.appendChild(link);
            link.click();
        
            // Limpiar el enlace temporal
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        });

    } catch (e) {

    }
}

export function* getExcerptsPDFSaga() {
    yield takeLatest("GETEXCERPTSPDF", getExcerptsPDF);
}

export function getExcerptsPDFAction(data) {
    return { type: "GETEXCERPTSPDF", payload: data }
}


function* sendWelcomeEmail({ payload }) {
    try {
        const userData = yield select(state => state.user);
        const { jwtToken } = userData;
        const { user } = payload;

        axios.post(`${API_URL}/user/sendwelcomeemail`, {user: user.id },{
            headers: { Authorization: `Bearer ${jwtToken}` }
        }).then((response) => {
            alert("Mensaje enviado")
        });

    } catch (e) {

    }
}

export function* sendWelcomeEmailSaga() {
    yield takeLatest("SENDWELCOMEEMAIL", sendWelcomeEmail);
}

export function sendWelcomeEmailAction(data) {
    return { type: "SENDWELCOMEEMAIL", payload: data }
}



function* sendRecoverUserEmail({ payload }) {
    try {
        const userData = yield select(state => state.user);
        const { jwtToken } = userData;
        const { user } = payload;

        axios.post(`${API_URL}/user/senduserrecoveremail`, {user: user.id },{
            headers: { Authorization: `Bearer ${jwtToken}` }
        }).then((response) => {
            alert("Mensaje enviado")
        });

    } catch (e) {

    }
}

export function* sendRecoverUserEmailSaga() {
    yield takeLatest("SENDRECOVERUSEREMAIL", sendRecoverUserEmail);
}

export function sendRecoverUserEmailAction(data) {
    return { type: "SENDRECOVERUSEREMAIL", payload: data }
}


function* sendRecoverUserPassEmail({ payload }) {
    try {
        const userData = yield select(state => state.user);
        const { jwtToken } = userData;
        const { user } = payload;

        axios.post(`${API_URL}/user/senduserrecoverpasstempemail`, {user: user.id },{
            headers: { Authorization: `Bearer ${jwtToken}` }
        }).then((response) => {
            alert("Mensaje enviado")
        });

    } catch (e) {

    }
}

export function* sendRecoverUserPassEmailSaga() {
    yield takeLatest("SENDRECOVERUSERPASSEMAIL", sendRecoverUserPassEmail);
}

export function sendRecoverUserPassEmailAction(data) {
    return { type: "SENDRECOVERUSERPASSEMAIL", payload: data }
}


